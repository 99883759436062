<!-- eslint-disable -->
<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
      <div @click="gotoDeliveries('Livrée')" style="cursor:pointer">
        <base-material-stats-card
          color="primary"
          icon="mdi-package-variant-closed"
          title="Livrée"
          :value="totalStats.total_delivered.toString()"
          sub-icon="mdi-clock"
          :sub-text="(totalStats.total_delivered*100/totalStats.total).toFixed(2).toString()+' %'"
        />
      </div>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
      <div @click="gotoDeliveries('EnRoute')" style="cursor:pointer">
        <base-material-stats-card
          color="#8E24AA"
          icon="mdi-truck-delivery"
          title="EnRoute"
          :value="totalStats.total_ongoing.toString()"
          sub-icon="mdi-tag"
          :sub-text="(totalStats.total_ongoing*100/totalStats.total).toFixed(2).toString()+' %'"
        >
        </base-material-stats-card>
      </div>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
      <div @click="gotoDeliveries('Chargement')" style="cursor:pointer">
        <base-material-stats-card
          color="#0096FF"
          icon="mdi-upload"
          title="Chargement"
          :value="totalStats.total_charged.toString()"
          sub-icon="mdi-calendar"
          :sub-text="(totalStats.total_charged*100/totalStats.total).toFixed(2).toString()+' %'"
        />
      </div>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
      <div @click="gotoDeliveries('Confirmé')" style="cursor:pointer">
        <base-material-stats-card
          color="#FBC02D"
          icon="mdi-check"
          title="Confirmé"
          :value="totalStats.total_confirmed.toString()"
          sub-icon="mdi-alert"
          :sub-text="(totalStats.total_confirmed*100/totalStats.total).toFixed(2).toString()+' %'"
        />
      </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
      <b-card title="Livraison TLM Logistique">
        <!-- <a @click="showOrHideStockHistoryConfig" href="#" class="float-right">Configuration</a> -->
          <v-btn color="success" class="mr-1" @click="getProviderDeliveryStats('one_month',true)">1 mois</v-btn>
          <v-btn color="success" class="mr-1" @click="getProviderDeliveryStats('three_months',true)">3 mois</v-btn>
          <v-btn color="success" class="mr-1" @click="getProviderDeliveryStats('six_months',true)">6 mois</v-btn>
          <v-btn color="success" class="mr-1" @click="getProviderDeliveryStats('all',true)">Tous</v-btn>
        <!-- </b-card> -->
        <br><br>
        <ve-line v-if="chartData.rows.length > 0" :data="chartData"></ve-line>
        <span v-else>Aucune livraison durant cette période</span>
      </b-card>
      </v-col>
      <!-- <span width="3" style="border-collapse:collapse;background-color:#24204d;width: 2px;padding:0px;"></span> -->
      <v-col
        cols="12"
        md="6"
      >
      <b-card title="Livraison TLM Express">
        <v-btn color="success" class="mr-1" @click="getProviderDeliveryStats('one_month',false)">1 mois</v-btn>
        <v-btn color="success" class="mr-1" @click="getProviderDeliveryStats('three_months',false)">3 mois</v-btn>
        <v-btn color="success" class="mr-1" @click="getProviderDeliveryStats('six_months',false)">6 mois</v-btn>
        <v-btn color="success" class="mr-1" @click="getProviderDeliveryStats('all',false)">Tous</v-btn>
        <br><br>
        <ve-line v-if="chartData2.rows.length > 0" :data="chartData2"></ve-line>
        <span v-else>Aucune livraison durant cette période</span>
      </b-card>
      </v-col>
      <!--  <base-material-card
          color="success"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light" style="width:100%">
              Stats des postes
              <b-form-checkbox @change="getAdminTopWorstPosts" v-model="isTop" name="check-button" switch class="float-right">
                Showing <b>{{(isTop == true ? 'Top':'Worst')}}</b> (Switch to <b>{{(isTop == true ? 'Worst':'Top')}}</b>)
              </b-form-checkbox>
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
              :items-per-page="5"
            >
            </v-data-table>
          </v-card-text>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <base-material-card
          color="success"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light" style="width:100%">
              Stats des entreprises
              <b-form-checkbox @change="getAdminTopWorstProviders" v-model="isProviderTop" name="check-button" switch class="float-right">
                Showing <b>{{(isProviderTop == true ? 'Top':'Worst')}}</b> (Switch to <b>{{(isProviderTop == true ? 'Worst':'Top')}}</b>)
              </b-form-checkbox>
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="providerHeaders"
              :items="providerItems"
              :items-per-page="5"
            >
              <template v-slot:item.total_returned="{ item }">
                <span>{{item.total_returned}}</span>
              </template>
            </v-data-table>
          </v-card-text>
        </base-material-card>
      </v-col> -->

      <!-- <v-col
        cols="12"
        md="6"
      >
        <base-material-card class="px-5 py-3">
          <template v-slot:heading>
            <v-tabs
              v-model="tabs"
              background-color="transparent"
              slider-color="white"
            >
              <span
                class="subheading font-weight-light mx-3"
                style="align-self: center"
              >Tasks:</span>
              <v-tab class="mr-3">
                <v-icon class="mr-2">
                  mdi-bug
                </v-icon>
                Bugs
              </v-tab>
              <v-tab class="mr-3">
                <v-icon class="mr-2">
                  mdi-code-tags
                </v-icon>
                Website
              </v-tab>
              <v-tab>
                <v-icon class="mr-2">
                  mdi-cloud
                </v-icon>
                Server
              </v-tab>
            </v-tabs>
          </template>

          <v-tabs-items
            v-model="tabs"
            class="transparent"
          >
            <v-tab-item
              v-for="n in 3"
              :key="n"
            >
              <v-card-text>
                <template v-for="(task, i) in tasks[tabs]">
                  <v-row
                    :key="i"
                    align="center"
                  >
                    <v-col cols="1">
                      <v-list-item-action>
                        <v-checkbox
                          v-model="task.value"
                          color="secondary"
                        />
                      </v-list-item-action>
                    </v-col>

                    <v-col cols="9">
                      <div
                        class="font-weight-light"
                        v-text="task.text"
                      />
                    </v-col>

                    <v-col
                      cols="2"
                      class="text-right"
                    >
                      <v-icon class="mx-1">
                        mdi-pencil
                      </v-icon>
                      <v-icon
                        color="error"
                        class="mx-1"
                      >
                        mdi-close
                      </v-icon>
                    </v-col>
                  </v-row>
                </template>
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </base-material-card>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable */
import VeLine from 'v-charts/lib/line.common'
import axios from 'axios'
  export default {
    name: 'DashboardDashboard',
    components: {VeLine},
    data () {
      return {
        statsLoading:false,
        chartData: {
            columns: ['date', 'Revenue', 'Bénifice'],
            rows: []
        },
        chartData2: {
            columns: ['date', 'Revenue', 'Bénifice'],
            rows: []
        },
        dailySalesChart: {
          data: {
            labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
            series: [
              [12, 17, 7, 17, 23, 18, 38],
            ],
          },
          options: {
            lineSmooth: this.$chartist.Interpolation.cardinal({
              tension: 0,
            }),
            low: 0,
            high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        },
        dataCompletedTasksChart: {
          data: {
            labels: ['12am', '3pm', '6pm', '9pm', '12pm', '3am', '6am', '9am'],
            series: [
              [230, 750, 450, 300, 280, 240, 200, 190],
            ],
          },
          options: {
            lineSmooth: this.$chartist.Interpolation.cardinal({
              tension: 0,
            }),
            low: 0,
            high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        },
        emailsSubscriptionChart: {
          data: {
            labels: ['Ja', 'Fe', 'Ma', 'Ap', 'Mai', 'Ju', 'Jul', 'Au', 'Se', 'Oc', 'No', 'De'],
            series: [
              [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],

            ],
          },
          options: {
            axisX: {
              showGrid: false,
            },
            low: 0,
            high: 1000,
            chartPadding: {
              top: 0,
              right: 5,
              bottom: 0,
              left: 0,
            },
          },
          responsiveOptions: [
            ['screen and (max-width: 640px)', {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0]
                },
              },
            }],
          ],
        },
        headers: [
          {
            sortable: false,
            text: 'N°',
            value: 'index',
          },
          {
            sortable: false,
            text: 'Nom',
            value: 'name',
          },
          {
            sortable: false,
            text: 'Wilaya',
            value: 'wilaya',
          },
          {
            sortable: false,
            text: 'N° commerçant',
            value: 'number_providers',
          },
          {
            sortable: false,
            text: 'Livraison Total',
            value: 'total_delivered',
            align: 'right',
          },
        ],
        providerHeaders: [
          {
            sortable: false,
            text: 'N°',
            value: 'index',
          },
          {
            sortable: false,
            text: 'Nom',
            value: 'name',
          },
          {
            sortable: false,
            text: 'Wilaya',
            value: 'wilaya',
          },
          {
            sortable: false,
            text: 'Facteur de Livraison',
            value: 'total_returned',
            align: 'right',
          },
        ],
        items: [],
        providerItems: [],
        tabs: 0,
        tasks: {
          0: [
            {
              text: 'Sign contract for "What are conference organizers afraid of?"',
              value: true,
            },
            {
              text: 'Lines From Great Russian Literature? Or E-mails From My Boss?',
              value: false,
            },
            {
              text: 'Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit',
              value: false,
            },
            {
              text: 'Create 4 Invisible User Experiences you Never Knew About',
              value: true,
            },
          ],
          1: [
            {
              text: 'Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit',
              value: true,
            },
            {
              text: 'Sign contract for "What are conference organizers afraid of?"',
              value: false,
            },
          ],
          2: [
            {
              text: 'Lines From Great Russian Literature? Or E-mails From My Boss?',
              value: false,
            },
            {
              text: 'Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit',
              value: true,
            },
            {
              text: 'Sign contract for "What are conference organizers afraid of?"',
              value: true,
            },
          ],
        },
        list: {
          0: false,
          1: false,
          2: false,
        },
        totalStats: {
          total_delivered: 0,
          total_ongoing: 0,
          total_onhold: 0,
          total_returned: 0,
          returned_rate: 0
        },
        isTop: true,
        isProviderTop: true,
        stockHistoryConfigDiv:false,
      }
    },
    mounted() {
      axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
      this.getTotalStats()
      this.getProviderDeliveryStats('all',true)
      this.getProviderDeliveryStats('all',false)
    },
    methods: {
      gotoDeliveries(status) {
        if (status == 'Confirmé') {
          this.$router.push({name: 'AdminRequests', query: {status: status}});
        } else {
          this.$router.push({name: 'AdminShippings', query: {status: status}});
        }
      },
      getProviderDeliveryStats(config,provider) {
        axios.post('provider-delivery-stats', {
          auth_token : localStorage.getItem('auth_token'),
          config:config,
          provider:provider
        }).then(response => {
          if (response.data.status == 'success') {
            if (provider == true) this.chartData.rows = response.data.data
            else this.chartData2.rows = response.data.data
          }
        })
      },
      showOrHideStockHistoryConfig() {
        this.stockHistoryConfigDiv = !this.stockHistoryConfigDiv
      },
      getTotalStats() {
        axios.post('admin-stats', {
          auth_token : localStorage.getItem('auth_token')
        }).then(response => {
          if (response.data.status == 'success') this.totalStats = response.data.data
        })
      },
      getAdminTopWorstPosts() {
        axios.post('admin-topworst-posts', {
          auth_token: localStorage.getItem('auth_token'),
          is_top : this.isTop
        }).then(response => {
          if (response.data.status == 'success') this.items = response.data.data
        })
      },
      getAdminTopWorstProviders() {
        axios.post('admin-topworst-providers', {
          auth_token: localStorage.getItem('auth_token'),
          is_top : this.isProviderTop
        }).then(response => {
          if (response.data.status == 'success') this.providerItems = response.data.data
        })
      },
      complete (index) {
        this.list[index] = !this.list[index]
      },
    },
  }
</script>
